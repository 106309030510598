<template>
    <div class='shop'>
        <tab-bar></tab-bar>
    </div>
</template>

<script>
import tabBar from "@/components/tabbar/index";
export default {
    components: {
        tabBar
    },
    data() {
        return {
            
        };
    },
    props:{
        
    },
    created() {
        
    },
    mounted() {
        
    },
    methods: {
        
    },
    watch: {
        
    },
    computed: {
        
    },
}
</script>
<style lang='scss' scoped>

</style>